import React from "react";
import "../Styles/terms.scss"; // Import your component-specific CSS file
import Header from "../components/Utils/header";
import Footer from "../components/Utils/footer";

const Terms = () => {
  return (
    <div className="terms">
      <Header />
      <div className="tnc">
        <div className="container">
          <h1>Terms and Conditions</h1>
          <p>Updated [26-11-2023]</p>

          <p>
            Thank you for visiting our website or using our app. This website is
            owned and operated by us. By accessing and/or using this website and
            apps and related services, you agree to these Terms and Conditions,
            which include our privacy policy. You should review our Privacy
            Policy and these Terms carefully and immediately cease using our
            website if you do not agree to these Terms. In these Terms, ‘us’,
            ‘we’ and ‘our’ means apps made our team and our related bodies
            corporate.
          </p>
          <h2>Consent</h2>

          <p>
            By visiting the Site, downloading the App, or otherwise using the
            Service, you indicate your agreement to be bound by these Terms. If
            you do not agree with these Terms, you must not use the Service, and
            uninstall the App if you have already downloaded it.
          </p>
          <h2>Buying in-app purchases(IAP) in our app</h2>

          <h5>
            • All prices are subject to change without notification. We
            occasionally run promotion prices as incentives or limited time
            opportunities for qualifying purchases made during the promotional
            time period. Due to the time sensitive and promotional nature of
            these events, we are unable to offer price protection or retroactive
            discounts or refunds for previous purchases in the event of a price
            reduction or promotional offering.
          </h5>
          <h5>
            • Payment will be charged to Google Account at confirmation of
            purchase.
          </h5>
          <h5>
            • Subscription automatically renews for the same price and duration
            period as the original package unless auto-renew is turned off at
            least 24-hours before the end of the current period.
          </h5>
          <h5>
            • Account will be charged for renewal within 24-hours prior to the
            end of the current period at the cost of the chosen package (weekly
            or monthly package).
          </h5>
          <h5>
            • Subscriptions may be managed by the user and auto-renewal may be
            turned off by going to the user’s Google Account Settings after
            purchase. No cancellation of the current subscription is allowed
            during active subscription period.
          </h5>
          <h5>
            • You may cancel a subscription during its free trial period via the
            subscription setting through your Google account. This must be done
            24 hours before the end of the subscription period to avoid being
            charged.
          </h5>
          <h5>
            • You may turn off the auto-renewal of your subscription via your
            Google Account Settings. However, you are not able to cancel the
            current subscription during its active period.
          </h5>
          <h5>
            • Any unused portion of a free-trial period, will be forfeited when
            the user purchases a Gallery Lock premium subscription.
          </h5>
          <h2>Updates to these Terms</h2>
          <p>
            We may modify these Terms from time to time. We will notify you of
            material changes in accordance with applicable laws. If you do not
            agree with the proposed changes, you should discontinue your use of
            the Service and uninstall the App. If you continue using the Service
            after the new terms take effect, you will be bound by the modified
            Terms.
          </p>
          <h2>Affirmative Representations</h2>

          <p>When you use the Service, you represent that:</p>

          <h5>
            • your use of the Service does not violate any applicable law or
            regulation;
          </h5>

          <h5>• you are 13 years of age or older;</h5>

          <p>
            you are of sufficient legal age or otherwise have legal capacity to
            legally enter into these Terms.
          </p>

          <h2>Our Intellectual Property Rights</h2>

          <p>
            The Service and the trademarks, service marks, and logos contained
            on the Service, are owned by or licensed to us and are subject to
            copyright and other intellectual property rights under United States
            and foreign laws and international conventions. The Service is for
            your information and personal use only and not for commercial
            exploitation.
          </p>
          <p>
            We reserve all rights in and to the Service. If you download or
            print a copy of the Service for your own personal use, you must
            retain all trademark, copyright and other proprietary notices
            contained in and on the Service.
          </p>
          <h2>Governing Law</h2>

          <p>
            This Terms will in all respects be governed by and construed and
            enforced in accordance with the laws of the State of California
            (without giving effect to any choice or conflict of laws). The
            United Nations Convention on Contracts for the International Sale of
            Goods will not apply to this Agreement.
          </p>

          <h2>How to Contact Us</h2>

          <p>
            If you have any questions about these Terms, please contact us as
            follows at ：
          </p>
          <a href="mailto:mutetidanz@gmail.com">mutetidanz@gmail.com</a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
