import React, { useEffect, useState } from "react";
import heroitem from "../../assets/images/heroitem.png";

export default function Hero(props) {
  return (
    <div className="landing_hero">
      <div className="nhero">
        <div className="left">
          <h1>About Mande Games</h1>
          <p>
            Mande Games is a leading mobile game development company
            specializing in the creation and delivery of diverse gaming
            experiences across various genres. With a passion for innovation and
            creativity, we craft engaging and immersive mobile games for players
            of all ages and interests.
          </p>
        </div>
        <img src={heroitem} alt="" />
      </div>
    </div>
  );
}

const MySlider = (props) => {
  const [animationActive, setAnimationActive] = useState(false);

  useEffect(() => {
    setAnimationActive(false);
    setTimeout(() => {
      setAnimationActive(true);
    }, 2000);
  }, [props.currentIndex]);

  return (
    <div
      className={`slideshow-image ${
        props.index === props.currentIndex ? "active" : ""
      }`}
    >
      <img src={props.item.img} />
      <div className={`content ${animationActive ? "animation-active" : ""}`}>
        <div className="left">
          <p>Key Features</p>
          {props.item.title}
          <p>Transforming Agriculture with Technology</p>
        </div>
      </div>
    </div>
  );
};
