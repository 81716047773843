import React from "react";
import "../Styles/terms.scss"; // Import your component-specific CSS file
import Header from "../components/Utils/header";
import Footer from "../components/Utils/footer";

const Privacy = () => {
  return (
    <div className="terms">
      <Header />
      <div className="tnc">
        <div className="container">
          <h1>Privacy Policy</h1>
          <p>Updated [26-11-2023]</p>

          <h2>Introduction</h2>
          <p>
            Hello! Welcome to the MANDE GAMES Privacy Policy. This policy
            describes how MANDE GAMES and, its other group companies
            (collectively described as “MANDE GAMES”, “we”, “us” or “our”) and
            its third party marketing and advertising partners collect, use,
            process and share information about you. This Privacy Policy applies
            to all of our games, whether played on our website, on mobile
            devices, PCs or on other platforms such as Facebook. In this Privacy
            Policy we refer to our games, websites, marketing and advertising
            activities and other services collectively as our “Services”. MANDE
            GAMES
          </p>

          <h2>Play our game</h2>
          <p>
            By downloading, accessing and/or playing our games, you agree to our
            collection of information related to you. Most of this data is
            collected directly from you or your device, such as device
            information, country and region, and game progress information. We
            will use this data to provide you with our games and services,
            including for optimization, fraud prevention, customizing in-game
            products, and confirmation of purchases.
          </p>
          <h2>Personal Data That We Collect</h2>
          <p>
            For purposes of this Privacy Policy, “personal data” means
            personally identifiable information that specifically identifies you
            as an individual.
          </p>
          <h4>a)Account Information</h4>
          <p>
            When you use our Apps or Services, you may register an account with
            us to track your progress and to sync your activity across devices.
            To register an account, you may be asked to provide certain personal
            information to us, including your name, email address, and password.
            You are solely responsible for ensuring your account information is
            correct and up-to-date.
          </p>

          <h4> b)Information From Social Media Platforms</h4>

          <p>
            Personal information collected by MANDE GAMES is information
            voluntarily provided to us by you when you create your account or
            change your account information. The information includes your
            Facebook id, name, gender, location and your friends’ id on
            Facebook. MANDE GAMES also stores your game checking, likes,
            dislikes, recommendations and messages.
          </p>

          <h4>c)Activity and Usage Data</h4>
          <p>
            MANDE GAMES may use collected personal data for purposes of
            analyzing usage of the Services, providing customer and technical
            support, managing and providing Services (including managing
            advertisement serving) and to further develop the Services and other
            MANDE GAMES services and products. MANDE GAMES may combine
            non-personal data with personal data.
          </p>
          <h4>d)non-personal information (Analytical Data)</h4>
          <p>
            We collect and use non-personal information (Analytical Data) during
            the use of our apps:
          </p>
          <p>
            1. Are there any errors in the app? Where and when do they occur?
          </p>
          <p>2. How long do users use our apps?</p>
          <p>
            3. How do our users use our apps, which features are most popular
            and which ones are used less.
          </p>
          <p>
            This helps us to gain a better understanding of our users, to draw
            conclusions for future concepts, and, ultimately, to develop better
            apps. In order to collect this information, we use Google Analytics.
          </p>
          <p>
            The privacy policies for these services are available here:Embed
            URLPaste a link to the content you want to display on your
            site.EmbedLearn more about embeds(opens in a new tab)Sorry, this
            content could not be embedded.Try again Convert to link
          </p>
          <p>
            Please note that certain features of the Services may be able to
            connect to your social networking sites to obtain additional
            information about you. In such cases, MANDE GAMES may be able to
            collect certain information from your social networking profile when
            your social networking site permits it, and when you consent to
            allow your social networking site to make that information available
            to MANDE GAMES. This information may include, but is not limited to,
            your name, profile picture, gender, user ID, email address, your
            country, your language, your time zone, the organizations and links
            on your profile page, the names and profile pictures of your social
            networking site friends and other information you have included in
            your social networking site profile. MANDE GAMES may associate
            and/or combine as well as use information collected by MANDE GAMES
            and/or obtained through such social networking sites in accordance
            with this Privacy Policy.
          </p>
          <h2>How We Use Your Personal Information</h2>
          <p>
            We will only use your personal information as described in this
            Policy or as disclosed to you prior to such processing taking place.
            The purposes for which we may use your personal information include:
          </p>
          <h5>1)To Provide Our Services</h5>
          <p>
            We use your personal information to provide you with the Apps and
            any other services, purchases or requests in connection with the
            Services you request. This may include processing transactions,
            providing you with the requested features, syncing your data across
            your devices logged into your account, customer service, etc.
          </p>
          <h5>2)To Personalize Your Experience</h5>
          <p>
            We may process your information to personalize the Services for you.
            For example, we use your language preferences and country location
            to display the correct language and time zone in the Services. We
            also use certain information about your device (such as operating
            system version and screen resolution information) to optimize the
            display of pictures on your screen.
          </p>
          <h5>3)To Send You Service-Related Communications</h5>
          <p>
            We will use your information (such as contact information) to send
            you communications about your account, requested transactions, and
            changes in our policies. Please note that you may not opt out of
            such communications, which are necessary to keep you informed about
            important updates about your account and the Services.
          </p>
          <h5>4)To Conduct Analytics and Other Internal Purposes</h5>

          <p>
            We may use your personal information to conduct analytics. This can
            be used for quality control, internal auditing, and to measure how
            our Apps, Services, and ad campaigns are performing. We also use
            your personal information to accumulate and report aggregate,
            statistical information in connection with the Apps, Services and
            user activity, in order to help us operate, enhance and improve our
            Services.
          </p>
          <h5>5)To Ensure Security of our Services</h5>

          <p>
            While we are offering services, it is used for the purposes of
            identity verification, customer service, security protection,
            combatting spam, malware, and malicious activity, fraud monitoring,
            archiving and backups, ensuring the security of the products and
            services that we provide to you.
          </p>
          <h2>Who We Share Your Information With</h2>

          <p>
            We share your Information with selected third parties, including:
          </p>
          <h5>
            1)Other players and users: Because social features are a core
            component of our Games, other players and users may see your profile
            information and in-game activities, as well as any other information
            you choose to share with them, such as chat data.
          </h5>
          <h5>
            2)Vendors and service providers we rely on for the provision of the
            Games, for example:
          </h5>
          <h5>
            3)Cloud service providers who we rely on for data storage, including
            Amazon Web Services who are based in the U.S.
          </h5>
          <h5>
            4)App Stores to allow you to make purchases via Google Play, IOS App
            Store, and maintain a record of such purchases. The information we
            are provided with includes your device ID and the in app purchase
            activity to enable us to provide you with the items you purchase
            from such App Stores.
          </h5>
          <h5>
            5)Player support solution providers, who help us manage and respond
            to our player questions and complaints. This includes HelpShift,
            Inc. who is based in the U.S. and who hosts our player support
            function;
          </h5>
          <h5>
            6)Analytics providers: We work with a number of analytics,
            segmentation and mobile measurement service providers who help us
            understand our userbase. This includes AppsFlyer and Firebase, who
            are based in the United States, and other analytics.
          </h5>
          <h5>
            7)Social media: Should you play our Games via Facebook, social media
            interaction tools may be utilized to enhance social media features
            in the game such as accessing your friends list and displaying your
            profile picture;
          </h5>
          <h5>
            8)User acquisition: We work with a variety of user acquisition
            partners to help us report on the success of our marketing
            campaigns. We may use some of your personal information to
            participate in Custom Audience and Lookalike Audience programmes,
            which enable us to display adverts to both existing and prospective
            players when they visit one of our partners’ websites.
          </h5>
          <h5>
            9)Advertising partners: Our Games are supported by ads. Subject to
            your settings, we provide certain Information to advertisers who
            will use them to serve you with ads in our Games, and we measure who
            sees and clicks on their ads. We also share advertising identifiers,
            along with the interests or other characteristics of a device or the
            person using it, to help partners decide whether to serve an ad to
            that device or to enable them to conduct marketing, brand analysis,
            interest-based advertising, or similar activities.
          </h5>
          <h5>
            10)Law enforcement agencies, public authorities or other judicial
            bodies and organisations: We disclose Information if we are legally
            required to do so, or if we have a good faith belief that such use
            is reasonably necessary to comply with a legal obligation, process
            or request; enforce our terms of service and other agreements,
            policies, and standards, including investigation of any potential
            violation thereof; detect, prevent or otherwise address security,
            fraud or technical issues; or protect the rights, property or safety
            of us, our users, a third party or the public as required or
            permitted by law (including exchanging information with other
            companies and organisations for the purposes of fraud protection).
          </h5>
          <h5>
            11)Change of corporate ownership. If we are involved in a merger,
            acquisition, bankruptcy, reorganisation, partnership, asset sale or
            other transaction, we may disclose your Information as part of that
            transaction.
          </h5>
          <h2>Where We Process Your Information</h2>

          <p>
            Our service is global by nature and your Information can be
            collected from players anywhere in the world. Currently your
            Information is processed by our employees and service providers in
            the European Economic Area (“EEA”), the UK, Israel and the USA.
            Israel and the UK are countries which the European Commission has
            decided offer an adequate level of data protection. Because
            countries outside of the EEA may have different data protection
            laws, we take steps to ensure that all transfers are protected by
            adequate safeguards, including the use of standard contractual
            clauses approved by the European Commission.
          </p>
          <h2>Opt-Out</h2>

          <p>
            You can opt-out of interest-based advertising in our Games by
            checking the privacy settings of your Android or iOS device and
            selecting “limit ad tracking” (Apple iOS) or “opt-out of
            interest-based ads” (Android). For more information, For
            personalized in-Game offers opt-out, you can use the options
            provided in the Game settings.
          </p>
          <h2>How Do We Protect Your Information</h2>

          <p>
            a)Security Safeguards: In order to help ensure a secure and safe
            player experience, we are continuously developing and implementing
            organisational, technical and physical security measures to protect
            your Information from unauthorized access or against loss, misuse or
            alteration. These measures include hashing of Information,
            segregating and limiting access to Information and requiring two
            factor authentication to access Information.
          </p>
          <p>
            B)Retention: We retain your Information for as long as needed to
            provide you the Games and up to three (3) years from the date on
            which you stop interacting with the Games.
          </p>
          <h2>Data Retention</h2>

          <p>
            MANDE GAMES retains your personal data for as long as needed to
            provide you Services, unless we are required by law to delete or if
            MANDE GAMESaccepts your request to delete the information. We will
            also retain and use your personal data as necessary to comply with
            our legal obligations, resolve disputes, and enforce our agreements.
          </p>
          <h2>Right to Deletion</h2>

          <h5>• How users request data deletion:</h5>
          <p>
            To exercise these rights, you can submit a request to exercise your
            rights in the MANDE GAMES Privacy Portal or through the Privacy
            category in the Support page within the game you play. You have the
            right to not be discriminated against for exercising any of your
            rights. Please understand that, if the exercise of your rights
            limits our ability to process personal data (such as a deletion
            request), we may no longer be able to provide you with the Service.
          </p>
          <p>
            Right to deletion. Subject to certain exceptions set out below, on
            receipt of a verified request from you, we will:
          </p>
          <h5>• Delete your personal information from our records;</h5>
          <h5>
            • Direct any service providers to delete your personal information
            from their records.Please note that we may not delete your personal
            information if it is necessary to:
          </h5>
          <h5>
            • Complete the transaction for which the personal information was
            collected, fulfill the terms of a written warranty or product recall
            conducted in accordance with federal law, provide a good or service
            requested by you, or reasonably anticipated within the context of
            our ongoing business relationship with you, or otherwise perform a
            contract between you and us;
          </h5>
          <h5>
            • Detect security incidents, protect against malicious, deceptive,
            fraudulent, or illegal activity; or prosecute those responsible for
            that activity;
          </h5>
          <h5>
            • Debug to identify and repair errors that impair existing intended
            functionality;
          </h5>
          <h5>
            • Exercise free speech, ensure the right of another consumer to
            exercise his or her right of free speech, or exercise another right
            provided for by law;
          </h5>
          <h5>
            • Engage in public or peer-reviewed scientific, historical, or
            statistical research in the public interest that adheres to all
            other applicable ethics and privacy laws, when our deletion of the
            information is likely to render impossible or seriously impair the
            achievement of such research, provided we have obtained your
            informed consent;
          </h5>
          <h5>
            • Enable solely internal uses that are reasonably aligned with your
            expectations based on your relationship with us;
          </h5>
          <h5>• Comply with an existing legal obligation; or</h5>

          <h5>
            • Otherwise use your personal information, internally, in a lawful
            manner that is compatible with the context in which you provided the
            information.
          </h5>
          <h2>Security</h2>

          <p>
            MANDE GAMES takes reasonable measures to protect your information
            from unauthorized access or against loss, misuse or alteration by
            third parties. Although we make good faith efforts to store the
            information collected on the Services in a secure operating
            environment that is not available to the public, we cannot guarantee
            the absolute security of that information during its transmission or
            its storage on our systems. Further, while we attempt to ensure the
            integrity and security of our network and systems, we cannot
            guarantee that our security measures will prevent third-party
            “hackers” from illegally obtaining access to this information. We do
            not warrant or represent that your information will be protected
            against, loss, misuse, or alteration by third parties. No method of
            transmission over the Internet, or method of electronic storage, is
            100% secure, however. Therefore, we cannot guarantee its absolute
            security.
          </p>
          <h2>Advertising</h2>

          <p>
            Our games may include advertising for third party products and
            services.
          </p>
          <p>
            MANDE GAMES and our advertising partners collect and use information
            about you to improve advertising, targeting and measurement systems
            so that we can show you relevant third party ads in our games. To
            provide our games, Websites and interest-based advertising, we use
            ad identifiers, cookies and similar technologies. This section
            describes our use of these technologies and how you can manage your
            preferences.
          </p>
          <h2>Children</h2>

          <p>
            You must be over a certain age to play our games and use our
            Services, depending on where you live. For the full list of age
            restrictions by country, please see below. We do not knowingly
            collect or solicit personal information from or direct or target
            interest based advertising to anyone under the ages set out below,
            or knowingly allow such persons to use our Services. If you believe
            that we might have any information from or about a child under the
            age set out below, please Contact Us.
          </p>
          <h2>Your rights</h2>

          <p>
            You have certain rights in connection with your personal information
            and how we handle it. You can exercise these rights at any time by
            contacting us via any of the methods set out in the Contact Us
            section below.
          </p>
          <h2>Contact us</h2>

          <p>
            To communicate with us and/or use your rights specified under this
            Privacy Policy please contact us at:
          </p>

          <a href="mailto:mutetidanz@gmail.com">mutetidanz@gmail.com</a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;
