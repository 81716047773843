import React from "react";
import "../Styles/landing.scss";
import Header from "./../components/Utils/header";
import Footer from "../components/Utils/footer";
import Hero from "../components/Home/Hero";
import Data from "../components/Home/Data";
import Services from "../components/Home/Services";
import Partners from "../components/Home/Partners";
import Benefits from "../components/Home/Benefits";
import AboutSection from "../components/Home/AboutSection";

export default function Home() {
  return (
    <div className="landing">
      <div className="headings">
        <Header />
      </div>
      <Hero />
      <AboutSection />
      <Footer />
    </div>
  );
}
