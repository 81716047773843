import React, { useEffect, useRef, useState } from "react";
import logo from "../../assets/images/logo.png";
import HeaderModal from "./modal_header";

function NavLink(props) {

  return (
    <div
      className="outer"
    >
      <div
        className="navlink"
      >
        {props.active === props.txt ? (
          <a
            data-aos="zoom-in"
            className="active"
            href={props.data?.length > 0 ? "#" : props.url}
          >
            {props.txt}
            {props.data?.length > 0 && <i className="fa ">&#xf107;</i>}
          </a>
        ) : (
          <a data-aos="zoom-in" href={props.txt == "Portals" ? "#" : props.url}>
            {props.txt}
            {props.data?.length > 0 && <i className="fa">&#xf107;</i>}
          </a>
        )}
      </div>
    </div>
  );
}


export default function Header(props) {
  const [clicked, setClicked] = useState(false);
  const hd = useRef();


  useEffect(() => {
    window.addEventListener("scroll", changeCss, false);
  }, []);

  function changeCss() {
    if (this.scrollY > 0) {
      hd.current.style.position = "fixed";
    } else {
      hd.current.style.position = "relative";
    }
  }

  return (
    <>
      <div ref={hd} className="header">
        <div className="contacts">
          <div className="container">
            <div>
              <img
                onClick={() => {
                  window.location.href = "/";
                }}
                src={logo}
                alt=""
              />
              <h1>Mande Games</h1>
            </div>

            <a href="mailto:mutetidanz@gmail.com">
              <i className="fa ">&#xf1fa;</i> mutetidanz@gmail.com
            </a>
          </div>
        </div>
        <div className="hcontainer">
          <div className="wrapper">
            <NavLink txt="Home" url="/" active={props.active} />
            <NavLink
              txt="Privacy Policy"
              url="/privacy"
              active={props.active}
            />
            <NavLink
              txt="Terms and Conditions"
              url="/terms"
              active={props.active}
            />
          </div>
          <div className="mob">
            <i
              onClick={() => {
                setClicked(true);
              }}
              className="fa"
            >
              &#xf0c9;
            </i>
          </div>
        </div>
      </div>
      {clicked && <HeaderModal logo={logo} setClicked={setClicked} />}
    </>
  );
}
