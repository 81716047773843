import team from "../../assets/images/team.png";
import languages from "../../assets/images/languages.png";
import creativity from "../../assets/images/creativity.png";
import entertain from "../../assets/images/entertain.png";
import logo from "../../assets/images/logo.png";

export default function AboutSection(props) {
  const content = [
    {
      img: team,
      title: "Our Team",
      desc: `Our team at Mande Games is dedicated to pushing the 
      boundaries of mobile gaming, offering a wide array of titles
       that cater to different preferences and playstyles. 
       From action-packed adventures to puzzle-solving challenges,
        simulation games, strategy, RPGs, casual games, and more,
         we strive to provide an extensive collection of 
         entertaining and captivating experiences.`,
    },
    {
      img: languages,
      title: "Our Commitment",
      desc: `At the heart of our company lies a commitment to excellence in game design, development, and user experience. We leverage cutting-edge technology, industry expertise, and a deep understanding of player behavior to deliver high-quality games that captivate and engage audiences worldwide.`,
    },
    {
      img: creativity,
      title: "Culture",
      desc: `Mande Games prides itself on fostering a culture of creativity, teamwork, and continuous improvement. Our talented and passionate team of developers, designers, artists, and storytellers collaborates tirelessly to bring innovative concepts to life and create memorable gaming experiences that resonate with our players.`,
    },
    {
      img: entertain,
      title: "Entartainment",
      desc: `With a focus on user satisfaction and entertainment, we aim to exceed expectations, delivering games that not only entertain but also leave a lasting impact. Whether it's through captivating narratives, stunning visuals, intuitive gameplay mechanics, or social interactions within our games, Mande Games is committed to delivering enjoyable experiences that players will love.`,
    },
    {
      img: logo,
      title: "Mande Games",
      desc: `Join us on our journey as we continue to explore new horizons in mobile gaming, striving to create remarkable games that inspire, entertain, and connect people around the world. Welcome to Mande Games—where imagination meets innovation in the world of mobile gaming.`,
    },
  ];

  return (
    <div className="about">
      <div className="container">
        {content.map((e, i) => {
          return (
            <div key={i} className="cover">
              <div className="item">
                <img src={e.img} alt="" />
                <div>
                  <h4>{e.title}</h4>
                  <p>{e.desc}</p>
                </div>
              </div>{" "}
            </div>
          );
        })}
      </div>
    </div>
  );
}
